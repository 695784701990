export default function getArticleType(index: number | string) {
  const id = parseInt(index.toString())
  if (isMm()) {
    switch (id) {
      case 71:
        return 'analysis'
      case 103:
        return 'history'
      case 116:
        return 'comment'
      case 117:
        return 'kultur'
      case 118:
        return 'newjb'
      case 121:
        return 'names'
      case 122:
        return 'techtendenser'
      case 123:
        return 'chiefblog'
      case 124:
        return 'newleadership'
      case 125:
        return 'kultur'
      default:
        return 'article'
    }
  } else if (isSite('altinget_se')) {
    switch (id) {
      case 1:
        return 'article'
      case 14:
        return 'debate'
      case 24:
        return 'review'
      case 71:
        return 'analysis'
      case 85:
        return 'video'
      case 90:
        return 'opinion'
      case 115:
        return 'podcast'
      case 116:
        return 'comment'
      case 93:
        return 'names'
      default:
        return 'article'
    }
  } else if (isSite('altinget_no')) {
    switch (id) {
      case 1:
        return 'article'
      case 14:
        return 'debate'
      case 24:
        return 'review'
      case 71:
        return 'analysis'
      case 72:
        return 'column'
      case 85:
        return 'video'
      case 100:
        return 'comment'
      case 135:
        return 'names'
      default:
        return 'article'
    }
  } else {
    // altinget_dk
    switch (id) {
      case 1:
        return 'article'
      case 14:
        return 'debate'
      case 24:
        return 'review'
      case 71:
        return 'analysis'
      case 85:
        return 'video'
      case 100:
        return 'opinion'
      case 107:
        return 'kronik'
      case 115:
        return 'podcast'
      case 116:
        return 'comment'
      case 135:
        return 'names'
      case 141:
        return 'politicalSpeech'
      default:
        return 'article'
    }
  }
}
